import { css } from '@emotion/react'
import { memo, useCallback, useMemo, type ChangeEvent, type SyntheticEvent } from 'react'

import { Autocomplete, TextField } from '@mui/material'
import type { CalendarDate } from '@orus.eu/calendar-date'
import { countries, countriesSchema, type Countries } from '@orus.eu/countries'

import {
  mutaSocialSecurityNumberDimension,
  mutaSocialSecurityOrganismNumberDimension,
  mutaSocialSecurityRegimeEntries,
} from '@orus.eu/dimensions'
import { spacing, useLanguage, useScreenType } from '../../../../index.js'
import { Avatar, Text, postcodeMapper } from '../../../atoms/index.js'
import { CheckboxContainer, LegacyDialog, PersistentNotification, Select, Tooltip } from '../../../index.js'
import {
  DatepickerFormField,
  TextFieldFormField,
  TextInputLabelWrapper,
  ValidatedTextFieldFormField,
} from '../../../molecules/index.js'
import { RowContainerWithRadio, type RadioItem } from '../../../molecules/row-container-with-radio/index.js'
import { getSocialSecurityNumberMapper } from './social-security-number-validation.js'
import { SocialSecurityNumberFormatter } from './social-security-number.js'
import { getSocialSecurityOrganismNumberMapper } from './social-security-organism-number-validation.js'
import { SocialSecurityOrganismNumberFormatter } from './social-security-organism-number.js'
import { SOCIAL_SECURITY_RANK_MAPPER } from './social-security-rank.js'

export type MemberModalProps = {
  emoji: string
  title: string
  lastNameValue: string
  firstNameValue: string
  birthdateValue: CalendarDate
  cityValue: string
  postCodeValue: string
  countryValue?: Countries
  socialSecurityNumberValue: string
  socialSecurityOrganismNumberValue?: string
  socialSecurityRankValue?: number
  linkedFamilyMember?: 'YOU' | 'PARTNER'
  socialRegimeValue?: string
  canDelete: boolean
  isPostQuote?: boolean
  isBackOffice?: boolean
  isChild?: boolean
  minimalSelectableDate?: CalendarDate | undefined
  onClose: () => void
  onSubmit?: () => void
  onChangeIsDisabledAdult: (newValue: boolean) => void
  isDisabledAdult: boolean
  onLastNameUpdate?: (newValue: string) => void
  onFirstNameUpdate: (newValue: string) => void
  onBirthdateUpdate: (newValue: CalendarDate | null) => void
  onPostCodeUpdate: (newValue: string) => void
  onCityUpdate?: (newValue: string) => void
  onCountryUpdate?: (newValue: Countries) => void
  onChangeSocialSecurityNumberUpdate?: (newValue: string) => void
  onChangeSocialSecurityOrganismNumberUpdate?: (newValue: string | undefined) => void
  onChangeSocialSecurityRankUpdate?: (newValue: number | undefined) => void
  onChangeLinkedFamilyMemberUpdate: (newValue: string | null) => void
  onChangeSocialRegimeUpdate?: (newValue: string) => void
  firstNameLastNameAlreadyExists: boolean
  regimes: RadioItem[]
  selectedRegime: RadioItem[]
  onDeleteMember?: () => void
  canSubmit: boolean
  referenceSocialSecurityNumbers?: string[]
  isCustomerApp?: boolean
}

const regionNamesInFrench = new Intl.DisplayNames(['fr'], { type: 'region' })

export const MemberModal = memo<MemberModalProps>(function MemberModal(props) {
  const language = useLanguage()
  const {
    emoji,
    title,
    onClose,
    onSubmit,
    onChangeIsDisabledAdult,
    isChild,
    isPostQuote,
    minimalSelectableDate,
    isDisabledAdult,
    regimes,
    selectedRegime,
    lastNameValue,
    firstNameValue,
    birthdateValue,
    postCodeValue,
    cityValue,
    countryValue,
    socialSecurityNumberValue,
    linkedFamilyMember,
    socialRegimeValue,
    socialSecurityOrganismNumberValue,
    socialSecurityRankValue,
    onLastNameUpdate,
    onFirstNameUpdate,
    onBirthdateUpdate,
    onPostCodeUpdate,
    onCityUpdate,
    onCountryUpdate,
    onChangeSocialSecurityNumberUpdate,
    onChangeSocialSecurityOrganismNumberUpdate,
    onChangeSocialSecurityRankUpdate,
    onChangeLinkedFamilyMemberUpdate,
    onChangeSocialRegimeUpdate,
    onDeleteMember,
    canDelete,
    canSubmit,
    isBackOffice,
    referenceSocialSecurityNumbers,
    isCustomerApp,
    firstNameLastNameAlreadyExists,
  } = props

  const variant = useScreenType()

  const handleChangeFirstNameEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onFirstNameUpdate) onFirstNameUpdate(event.target.value)
    },
    [onFirstNameUpdate],
  )

  const handleChangeLastNameEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onLastNameUpdate) onLastNameUpdate(event.target.value)
    },
    [onLastNameUpdate],
  )

  const handleChangePostCodeEvent = useCallback(
    (event: string | undefined) => {
      if (onPostCodeUpdate) onPostCodeUpdate(event ?? '')
    },
    [onPostCodeUpdate],
  )

  const handleChangeSocialSecurityNumber = useCallback(
    (event: string | undefined) => {
      if (onChangeSocialSecurityNumberUpdate) onChangeSocialSecurityNumberUpdate(event ?? '')
    },
    [onChangeSocialSecurityNumberUpdate],
  )

  const handleChangeSocialSecurityOrganismNumberUpdate = useCallback(
    (event: string | undefined) => {
      if (onChangeSocialSecurityOrganismNumberUpdate) onChangeSocialSecurityOrganismNumberUpdate(event)
    },
    [onChangeSocialSecurityOrganismNumberUpdate],
  )

  const handleChangeSocialSecurityRankUpdate = useCallback(
    (event: string | undefined) => {
      onChangeSocialSecurityRankUpdate?.(event ? parseInt(event, 10) : undefined)
    },
    [onChangeSocialSecurityRankUpdate],
  )

  const handleChangeCityEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (onCityUpdate) onCityUpdate(event.target.value)
    },
    [onCityUpdate],
  )

  const handleChangeCountryEvent = useCallback(
    (_: SyntheticEvent<Element, Event>, value: string | null) => {
      if (onCountryUpdate) {
        const countryCode = countriesSchema.safeParse(value)
        if (countryCode.success) {
          onCountryUpdate(countryCode.data)
        }
      }
    },
    [onCountryUpdate],
  )

  const handleChangeSocialRegime = useCallback(
    (_: ChangeEvent<HTMLInputElement>, value: string) => {
      console.log('VALUE', value)
      if (onChangeSocialRegimeUpdate) onChangeSocialRegimeUpdate(value)
    },
    [onChangeSocialRegimeUpdate],
  )

  const socialSecurityMapper = useMemo(() => {
    return getSocialSecurityNumberMapper({
      birthDate: birthdateValue,
      referenceSocialSecurityNumbers: isChild ? referenceSocialSecurityNumbers : undefined,
    })
  }, [birthdateValue, isChild, referenceSocialSecurityNumbers])

  const socialSecurityOrganismNumberMapper = useMemo(() => {
    const regimeToCheck = isBackOffice ? socialRegimeValue : selectedRegime[0]?.title
    const regime = mutaSocialSecurityRegimeEntries.find((regime) => regime[1].label === regimeToCheck)?.[0]
    return getSocialSecurityOrganismNumberMapper(regime)
  }, [isBackOffice, selectedRegime, socialRegimeValue])

  if (isPostQuote && isBackOffice) {
    return (
      <div>
        <Text variant="h1">Unexpected component usage</Text>
      </div>
    )
  }

  return (
    <LegacyDialog
      header={
        <div>
          <Avatar src={emoji} size="60" />
          <Text
            variant="h4"
            css={css`
              margin-top: ${spacing[50]};
            `}
          >
            {title}
          </Text>
        </div>
      }
      maxWidth="sm"
      onClose={onClose}
      onSubmit={onSubmit}
      secondaryActionLabel="Annuler"
      onSecondaryAction={onClose}
      submitDisabled={!canSubmit}
      submitLabel="Valider"
      tertiaryActionLabel={!isPostQuote && canDelete ? 'Supprimer ce membre' : undefined}
      onTertiaryAction={onDeleteMember}
    >
      <div
        css={css`
          min-width: ${variant === 'desktop' ? '600px' : ''};
        `}
      >
        {isPostQuote || isBackOffice ? (
          <>
            <div
              css={css`
                display: ${variant === 'desktop' ? 'flex' : 'block'};
                flex-direction: row;
                gap: ${spacing[50]};
                margin-bottom: ${variant === 'desktop' ? spacing[50] : ''};
              `}
            >
              <div
                css={css`
                  flex: 1;
                  margin-bottom: ${variant === 'mobile' ? spacing[60] : ''};
                `}
              >
                <TextFieldFormField
                  aria-label="Prénom du bénéficiaire"
                  size="large"
                  label="Prénom"
                  placeholder="Prénom"
                  value={firstNameValue}
                  onChange={handleChangeFirstNameEvent}
                  disabled={isCustomerApp}
                />
              </div>
              <div
                css={css`
                  flex: 1;
                  margin-bottom: ${variant === 'mobile' ? spacing[60] : ''};
                `}
              >
                <TextFieldFormField
                  aria-label="Nom du bénéficiaire"
                  size="large"
                  label="Nom"
                  placeholder="Nom"
                  value={lastNameValue}
                  onChange={handleChangeLastNameEvent}
                  disabled={isCustomerApp}
                />
              </div>
            </div>

            {firstNameLastNameAlreadyExists ? (
              <PersistentNotification
                variant="danger"
                css={css`
                  margin-bottom: ${variant === 'desktop' ? spacing[50] : spacing[60]};
                `}
              >
                Un autre bénéficiaire a déjà ce nom et ce prénom. Veuillez modifier le prénom ou le nom du bénéficiaire.
              </PersistentNotification>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div
            css={css`
              margin-bottom: ${variant === 'desktop' ? spacing[50] : spacing[60]};
            `}
          >
            <TextFieldFormField
              aria-label="Prénom du bénéficiaire"
              size="large"
              label="Prénom"
              placeholder="Prénom"
              value={firstNameValue}
              onChange={handleChangeFirstNameEvent}
              disabled={isCustomerApp}
            />
          </div>
        )}

        <div
          css={css`
            display: ${variant === 'desktop' ? 'flex' : 'block'};
            flex-direction: row;
            gap: ${spacing[50]};
            margin-bottom: ${variant === 'mobile' ? 0 : ''};
          `}
        >
          <div
            css={css`
              flex: 1;
            `}
          >
            <DatepickerFormField
              aria-label="Date de naissance du bénéficiaire"
              label="Date de naissance"
              size="large"
              onChange={onBirthdateUpdate}
              disabled={(isPostQuote || isCustomerApp) && !isBackOffice}
              minDate={minimalSelectableDate}
              value={birthdateValue}
              css={css`
                margin-bottom: ${variant === 'mobile' ? spacing[60] : ''};
              `}
              infoTooltip={
                isPostQuote ? 'Si vous souhaitez modifier ce champ, il faut revenir aux étapes précédentes.' : undefined
              }
            />
          </div>

          <div
            css={css`
              flex: 1;
            `}
          >
            <ValidatedTextFieldFormField
              size="large"
              aria-label="Code postal de résidence du bénéficiaire"
              label="Code postal de résidence"
              placeholder="75009"
              disabled={isPostQuote || isCustomerApp}
              onChange={handleChangePostCodeEvent}
              value={postCodeValue}
              mapper={postcodeMapper}
              css={css`
                margin-bottom: ${variant === 'mobile' ? spacing[60] : ''};
              `}
              infoTooltip={
                isPostQuote ? 'Si vous souhaitez modifier ce champ, il faut revenir aux étapes précédentes.' : undefined
              }
            />
          </div>
        </div>

        {isPostQuote || isBackOffice ? (
          <div
            css={css`
              margin-top: ${variant === 'desktop' ? spacing[50] : ''};
            `}
          >
            <ValidatedTextFieldFormField
              aria-label="Numéro de sécurité sociale du bénéficiaire"
              size="large"
              label={mutaSocialSecurityNumberDimension.displayNames[language]}
              placeholder={
                mutaSocialSecurityNumberDimension.placeholders
                  ? mutaSocialSecurityNumberDimension.placeholders[language]
                  : undefined
              }
              onChange={handleChangeSocialSecurityNumber}
              value={socialSecurityNumberValue}
              mapper={socialSecurityMapper}
              InputProps={{ inputComponent: SocialSecurityNumberFormatter }}
              caption={isCustomerApp ? undefined : 'Exemple : 1 98 06 12 123 987 55'}
              disabled={isCustomerApp}
            />
          </div>
        ) : undefined}

        {isPostQuote || isBackOffice ? (
          <div
            css={css`
              margin-top: ${variant === 'desktop' ? spacing[50] : spacing[60]};
              display: flex;
              flex-direction: ${variant === 'desktop' ? 'row' : 'column'};
              gap: ${spacing[50]};
            `}
          >
            <div
              css={css`
                flex: 1;
              `}
            >
              <ValidatedTextFieldFormField
                aria-label="Numéro d'organisme de sécurité du bénéficiaire"
                size="large"
                label={mutaSocialSecurityOrganismNumberDimension.displayNames[language]}
                value={socialSecurityOrganismNumberValue}
                placeholder={'01 011 0000'}
                onChange={handleChangeSocialSecurityOrganismNumberUpdate}
                mapper={socialSecurityOrganismNumberMapper}
                infoTooltip={
                  mutaSocialSecurityOrganismNumberDimension.hints
                    ? mutaSocialSecurityOrganismNumberDimension.hints[language]
                    : undefined
                }
                InputProps={{ inputComponent: SocialSecurityOrganismNumberFormatter }}
                caption={isCustomerApp ? undefined : 'Exemple : 01 751 0000'}
                disabled={isCustomerApp}
              />
            </div>
            {isChild ? (
              <ValidatedTextFieldFormField
                aria-label="Rang"
                size="large"
                label="Rang"
                value={socialSecurityRankValue?.toString()}
                placeholder="1"
                onChange={handleChangeSocialSecurityRankUpdate}
                mapper={SOCIAL_SECURITY_RANK_MAPPER}
                infoTooltip={`* Si vous êtes au régime général (TNS ou salarié), tous vos enfants sont de rang 1, sauf les jumeaux et les triplés.
* Si vous êtes soumis à un autre régime, vous pouvez trouver le rang de vos enfants sur votre attestation de droits.`}
                disabled={isCustomerApp}
              />
            ) : (
              <></>
            )}
          </div>
        ) : undefined}

        {isPostQuote || isBackOffice ? (
          <div>
            <Text
              variant="subtitle2"
              css={css`
                margin-bottom: ${variant === 'desktop' ? spacing[70] : spacing[60]};
                margin-top: ${variant === 'desktop' ? spacing[70] : spacing[60]};
              `}
            >
              Son lieu de naissance
            </Text>
            <div
              css={css`
                display: ${variant === 'desktop' ? 'flex' : 'block'};
                flex-direction: row;
                gap: ${spacing[50]};
              `}
            >
              <div
                css={css`
                  flex: 1;
                `}
              >
                <TextFieldFormField
                  aria-label="Ville de naissance du bénéficiaire"
                  size="large"
                  label="Ville"
                  placeholder="Paris"
                  onChange={handleChangeCityEvent}
                  value={cityValue}
                  css={css`
                    margin-bottom: ${variant === 'mobile' ? spacing[60] : ''};
                  `}
                  disabled={isCustomerApp}
                />
              </div>
              <div
                css={css`
                  flex: 1;
                `}
              >
                <TextInputLabelWrapper label="Pays">
                  <Autocomplete
                    id="country-select-beneficiary"
                    aria-label="Pays de naissance du bénéficiaire"
                    options={countries}
                    getOptionLabel={(option) => regionNamesInFrench.of(option) ?? 'N/A'}
                    onChange={handleChangeCountryEvent}
                    value={countryValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        placeholder=""
                        disabled={isCustomerApp}
                      />
                    )}
                  />
                </TextInputLabelWrapper>
              </div>
            </div>
          </div>
        ) : undefined}

        <div
          css={css`
            margin-bottom: ${variant === 'mobile' ? (isPostQuote ? spacing[70] : 0) : ''};
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: ${spacing[30]};
              align-items: center;
            `}
          >
            <Text
              variant="subtitle2"
              css={css`
                margin-bottom: ${variant === 'desktop' ? spacing[70] : spacing[60]};
                margin-top: ${variant === 'desktop' ? spacing[70] : isPostQuote ? spacing[60] : 0};
              `}
            >
              Son régime social
            </Text>
            {isPostQuote ? (
              <Tooltip title="Si vous souhaitez modifier ce champ, il faut revenir aux étapes précédentes.">
                <Avatar size="30" icon="circle-info-regular" />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>

          {(isPostQuote || isCustomerApp) && !isBackOffice ? (
            <RowContainerWithRadio items={selectedRegime} />
          ) : (
            <RowContainerWithRadio items={regimes} handleChange={handleChangeSocialRegime} value={socialRegimeValue} />
          )}
        </div>

        {isChild ? (
          <div
            css={css`
              margin-bottom: ${variant === 'desktop' ? spacing[70] : spacing[60]};
              margin-top: ${variant === 'desktop' ? spacing[70] : spacing[60]};
            `}
          >
            <CheckboxContainer
              checked={isDisabledAdult}
              onChange={onChangeIsDisabledAdult}
              disabled={(isPostQuote || isCustomerApp) && !isBackOffice}
              size="medium"
            >
              <Text variant="body2">Majeur handicapé déclaré à la charge de l&apos;assuré principal ou conjoint</Text>
            </CheckboxContainer>
          </div>
        ) : (
          <></>
        )}
        {isChild && linkedFamilyMember ? (
          <div
            css={css`
              margin-bottom: ${variant === 'desktop' ? spacing[70] : 0};
              margin-top: ${variant === 'desktop' ? spacing[70] : spacing[60]};
            `}
          >
            <TextInputLabelWrapper
              label="Parent de rattachement"
              css={css`
                flex: 1;
                width: 100%;
              `}
            >
              <Select
                aria-label="Parent de rattachement du bénéficiaire"
                disabled={isPostQuote && !isBackOffice}
                value={linkedFamilyMember}
                values={['YOU', 'PARTNER']}
                onChange={onChangeLinkedFamilyMemberUpdate}
                size="large"
                placeholder="Vous"
                labels={{
                  YOU: 'Vous',
                  PARTNER: 'Votre conjoint',
                }}
              />
            </TextInputLabelWrapper>
          </div>
        ) : (
          <></>
        )}
      </div>
    </LegacyDialog>
  )
})
